import http from "../http-common";

const getAll = () => {
  return http.get(`/orders`);
};
const getAllwithproducts = () => {
  return http.get(`/orders/all`);
};
/*const getMain = () => {
  return http.get(`/orders/main`);
};*/
const get = (id) => {
  return http.get(`/orders/id/${id}`);
};

/**/

const create = (data) => {
  return http.post(`/orders`, data);
};

const save = (id, data) => {
  //console.log(id);
  return http.put(`/orders/save`, data);
};
const update = (id, data) => {
  //console.log(id);
  return http.put(`/orders/product/${id}`, data);
};

const remove = (id) => {
  return http.delete(`/orders/delete/${id}`);
};

/*const removeAll = () => {
  return http.delete(`/orders`);
};

const findByTitle = title => {
  return http.get(`/orders?title=${title}`);
};
*/
const OrdersService = {
  getAll,
  getAllwithproducts,
  save,
  get,
  create,
  update,
  remove,
};

//export default PageService;
export { OrdersService };
