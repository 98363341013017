import React, { useEffect, useState, useRef } from "react";

import { Link, useParams } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
//import axios from "axios";
//import http from "../../http-common";
import UploadService from "../../_services/FileUploadService";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { authActions } from "_store";
import { ProjectService, alertService, accountService } from "../../_services";
import { projectActions } from "../../_store";
import AutoSelect from "../../_helpers/autoselect";

import Select, { OptionsType, ValueType } from "react-select";
import { Options, OnChangeValue } from "react-select";
import slugify from "react-slugify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./style.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import slugify from 'react-slugify';
import { Box } from "@mui/material";
import Header from "../../components/Header";
import Program from "./Program";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import RichTextEditor from "../../_helpers/RichTextEditor";
import { CloseButton } from "../../components/styles/CalendarStyles";
import Modal from "react-modal";
import Dragimg from "../../hoc/Dragimg";
import BiblImg from "../BiblImg";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { WriteSeo } from "../seo";

const Arrays = (data, fieldName, fieldValue) => {
  let arrayItem = [];
  if (data && Array.isArray(data)) {
    data.map((item, key) => {
      arrayItem.push({ label: item[fieldName], value: item[fieldValue] });
      return null;
    });
  }
  return arrayItem;
};

const WriteProject = () => {
  const state = useLocation().state;
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(state || "");
  const [id, setId] = useState(state?.id || 0);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [value, setValue] = useState(state?.description || "");
  //const [shortd, setShortD] = useState(state?.shortDescription || "");
  const [description, setDescription] = useState(state?.description || "");
  const [title, setTitle] = useState(state?.title || "");
  const [active, setActive] = useState(state?.active || false);
  const [slug, setSlug] = useState(state?.slug || "");
  const [client, setClient] = useState(state?.client || "");
  const [film, setFilm] = useState(state?.film || "");
  const [prevHref, setPrevHref] = useState(state?.prevHref || "");
  const [nextHref, setNextHref] = useState(state?.nextHref || "");
  //const [moreImages, setMoreImages] = useState(JSON.parse(state?.moreImages) || []);
  console.log("state?.moreImages", state?.moreImages, state?.moreImages.length);
  const [moreImages, setMoreImages] = useState(
    state?.moreImages.length > 2 ? state?.moreImages : {}
  );
  console.log(moreImages);
  const [shortDescription, setShortDescription] = useState(
    state?.shortDescription || ""
  );
  //const [isGeneralist, setIsGeneralist] = useState(state?.isGeneralist || false);
  //const [lastName, setLastName] = useState(state?.lastName || "");
  const [file, setFile] = useState(null);
  const [pimg, setPimg] = useState(state?.coverImage || "");
  // const [price, setPrice] = useState(state?.price || 0);
  // const [positionId, setPositionId] = useState(Arrays(state?.Position , "title", "id")|| []);
  //const [positionId, setPositionId] = useState(state?.PositionId || "");
  const [userId, setUserId] = useState(state?.UserId || "");
  console.log("userId :", userId);
  //const [userId, setUserId] = useState(Arrays(state?.User , "username", "id")|| []);
  const [allmain, setAllmain] = useState(null);
  const [allcateg, setAllcateg] = useState(null);
  const [allproject, setAllproject] = useState(null);
  const [users, setUsers] = useState(null);
  const dispatch = useDispatch();
  const { user: authUser } = useSelector((x) => x.auth);
  // const { users } = useSelector(x => x.users);
  console.log("authUser", authUser?.id);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedModal, setSelectedModal] = useState("Addimg");
  const [open, setOpen] = useState(false);
  const [gal, setGal] = useState(state?.moreImages.length > 2 ? true : false);
  //console.log("MoreImage", Arrays(JSON.parse(moreImages)), JSON.stringify(moreImages), JSON.parse(moreImages));
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  /* content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    overlay: { zIndex: 1000 },
          top: "10%",
      left: "200",
      right: "auto",
      bottom: "auto",
      background: "papayawhip",
    */
  const customModalStyles = {
    content: {
      position: "absolute",
      backgroundColor: "#FFF",
      padding: "15px",
      zIndex: "1000",
      width: "85%",
      left: "30",
      borderRadius: ".5em",
    },
    overlay: {
      position: "fixed",
      display: "flex",
      justifyContent: "center",
      top: "0",
      left: "10%",
      width: "90%",
      height: "100%",
      backgroundColor: "rgba(0,0,0, .8)",
      zIndex: "1000",
      overflowY: "auto",
    },
  };
  const openForm = () => {
    setSelectedModal("Addimg");
    console.log("selectedModal: ",selectedModal);
    openModal();
  };
  const openSeo = () => {
    setSelectedModal("Addseo");
    console.log("selectedModal: ",selectedModal);
    openModal();
  };

  // Open Modal Function
  const openModal = () => {
    setModalOpen(true);
  };

  // Close Modal Function
  const closeModal = async (e) => {
    //console.log("urrentPage", currentPage);

    setModalOpen(false);
    if (e !== undefined) {
    if (e.id !== undefined) {
      console.log("inchid", e.id, e);
      console.log("currentPage", currentPage);
      //const kk1 = [...currentPage,{Seo : {e}}];
      //console.log("kk1", kk1);
      // setCurrentPage()
      setCurrentPage({ ...currentPage, Seo: e });
      console.log("urrentPage", currentPage);
    }
  }
    
  };

  const isAddMode = !id;
  //const authUser = useSelector(x => x.auth.user);
  const initialValues = {
    title: title,
    shortDescription: shortDescription,
    description: value,
    coverImage: pimg,
    moreImages: moreImages,
    client: client,
    film: film,
    prevHref: prevHref,
    nextHref: nextHref,
    slug: slug,
    active: active,
  };

  //const dispatch = useDispatch();

  useEffect(() => {
    //ProjectService.getAllposition().then(x => setAllmain(x.data));
    accountService.getAll().then((x) => setUsers(x));
    /*setMoreImages([
      "/Project-showcase/Karachi/Street-view.jpg",
      "/Project-showcase/Karachi/City-view.jpg",
      "/Project-showcase/Karachi/Kharadar-street.jpg",
      "/Project-showcase/Karachi/Flyover-view.jpg",
    ]);*/
  }, []);
  console.log("more", moreImages.length, gal);
  //const { categories } = useSelector(x => x.categories);

  //const { categories } = useSelector(x => x.categories);
  //if(categories) {
  //  setAllmain(categories);
  //}
  //console.log(allmain);
  if (allmain && !allcateg) {
    console.log("Functii:", allmain);
    setAllcateg(Arrays(allmain, "title", "id"));
    console.log("pos:", allcateg);
  }
  if (users && !allproject) {
    //console.log(allmain.data);
    console.log(users);
    setAllproject(Arrays(users, "username", "id"));
    console.log(allproject);
    //console.log(allcateg);
  }
  //   //console.log(categories);
  //useSelector(x => x.categories);
  ////console.log(categ );

  const filesharhe_ref = useRef();
  const upload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await UploadService.upload(file);
      setPimg(res.data);
      // setPimg(res.data.message]);
      //console.log(res.data.message);
      return res.data;
    } catch (err) {
      //console.log(err);
      toast.error(err, {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  };

  async function loadimg() {
    if (file) {
      const imgUrl = await upload();
    }
  }

  const handleClick = async (e) => {
    e.preventDefault();
    setFile(e.target.files[0]);
    setPimg(e.target.files[0].name);
    let images = [];

    for (let i = 0; i < e.target.files.length; i++) {
      images.push(URL.createObjectURL(e.target.files[i]));
    }

    //  setSelectedFiles(event.target.files);
    setImagePreviews(images);
    // //window.alert(imagePreviews);
    //  //window.alert(JSON.stringify({ imagePreviews }));
    /* if(file) {
     const imgUrl = await upload();
          setPimg(imgUrl.message);
     // //window.alert(pimg);
     // fields["img"]=pimg;
   };*/
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Titlu este obligatoriu"),
    myfile: Yup.mixed().test(
      "2097152",
      "Fișierul încărcat este prea mare.",
      (value) => {
        return value && filesharhe_ref.current
          ? filesharhe_ref.current.files[0].size <= 2097152
            ? true
            : false
          : true;
      }
    ),
  });

  async function onSubmit(fields, { setStatus, setSubmitting }) {
    try {
    setStatus();
   // loadimg();

    console.log(fields);
    if (file) {
      const imgUrl = await upload();
      //  fields["avatar"]=imgUrl;
      //  setPimg(imgUrl);

      if (imgUrl) {
        fields["coverImage"] = imgUrl;
        setPimg(imgUrl);
        console.log("imgUrl : ", imgUrl, fields["avatar"]);
      }
      // setPimg(imgUrl);
      // return true
    }
    fields["description"] = value;
    fields["shortDescription"] = shortDescription;
    //fields["coverImage"] = pimg;
    fields["active"] = active;
    fields["slug"] = slugify(fields["title"]);
    fields["UserId"] = authUser?.id;
    fields["moreImages"] = moreImages;
    console.log("fields", fields);
    console.log("moreImages", moreImages);
    //userId
    // fields["isGeneralist"] = isGeneralist;
    // window.alert(JSON.stringify({ fields}));
    // console.log(Object.values(PositionId)[1]);
    // console.log(Object.values(UserId)[1]);
    //const Pid = PositionId.map((e) => e.value);
    //console.log(Pid );

    // fields["slug"] = slugify(fields["title"]);
    //window.alert(JSON.stringify({ fields }));
    if (isAddMode) {
      createProject(fields, setSubmitting);
    } else {
      updateProject(id, fields, setSubmitting);
    }
  } catch (err) {
    toast.error(err, {
      position: toast.POSITION.TOP_LEFT,
    });
    // return false;
  }
  }

  function createProject(fields, setSubmitting) {
    ProjectService.create(fields)
      .then(() => {
        toast.success("Project adăugat cu succes");
        alertService.success("Produs adăugat cu succes", {
          keepAfterRouteChange: true,
        });

        navigate("/project");
      })
      .catch((error) => {
        setSubmitting(false);
        //console.log(error);
        toast.error(error.response.data.message);

        alertService.error(error);
      });
  }

  function updateProject(id, fields, setSubmitting) {
    //console.log("Update"+id);
    ProjectService.update(id, fields)
      .then((num) => {
        console.log("Num :", num);
        alertService.success("Update successful", {
          keepAfterRouteChange: true,
        });
        toast.success("Actualizare cu succes " + num.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate("/project");
      })
      .catch((error) => {
        setSubmitting(false);
        console.log(error.response.data.message);
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        alertService.error(error);
      });
  }

  const handleChange = (values) => {
    // const values = [...itemList];
    // values[index].value = e.target.value;
    setGal(false);
    setMoreImages("");
    setMoreImages(values);
    setModalOpen(false);
    
    setGal(true);
    //setGal(gal => ({...gal, true }));
    console.log("more 2 : ", moreImages, values, gal);
    if (moreImages.lenght===undefined) {
      setGal(false);
    }
    //console.log("id",e.target.id, e.target.type  );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, isSubmitting, setFieldValue }) => {
        return (
          <Box m="20px">
            <Form>
              <h1>
                {isAddMode ? "Adaugă Project" : "Editează  Project"}
                {isAddMode ? (
                  ""
                ) : (
                  <Button
                    color="secondary"
                    startIcon={<AddIcon />}
                    onClick={openSeo}
                  >
                    Seo
                  </Button>
                )}{" "}
              </h1>
              {/*   {isAddMode ? '' :             <Button color="secondary" startIcon={<AddIcon />} onClick={openForm}>
        Program de lucru
        </Button>} */}
              <div className="form-row">
                <div className="form-group col-9">
                  <label>Titlu</label>
                  <Field
                    name="title"
                    type="text"
                    className={
                      "form-control" +
                      (errors.title && touched.title ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group col-3">
                  <label>Client</label>
                  <Field
                    name="client"
                    type="text"
                    className={
                      "form-control" +
                      (errors.client && touched.client ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="client"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-3">
                  <label>
                    Status{" "}
                    {active ? (
                      <div className="badge badge-primary">Activ</div>
                    ) : (
                      <div className="badge badge-danger">Inactiv</div>
                    )}
                    <Field
                      style={{ marginLeft: "1.5em", marginTop: "1.3em" }}
                      type="checkbox"
                      name="active"
                      onChange={(e) => setActive(!active)}
                      className={
                        "form-control d-none" +
                        (errors.active && touched.active ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="active"
                      component="div"
                      className="invalid-feedback"
                    />
                  </label>
                </div>

                <div className="form-group col-9">
                  <small>
                    Sunt acceptate poze doar cu extensia jpg, gif, png, webp sub
                    2Mb. Rezolutie recomandata 800x400
                  </small>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-8">
                  <label>Rezumat</label>
                  {/* <Field name="shortDescription" type="text"  className={'form-control' + (errors.shortDescription && touched.shortDescription ? ' is-invalid' : '')} /> */}
                  <ReactQuill
                    className="editor"
                    theme="snow"
                    value={shortDescription}
                    onChange={setShortDescription}
                  />
                  <ErrorMessage
                    name="shortDescription"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group col-4">
                  <label>Film</label>
                  <Field
                    name="film"
                    type="text"
                    className={
                      "form-control" +
                      (errors.film && touched.film ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="film"
                    component="div"
                    className="invalid-feedback"
                  />
                  <Field
                    name="prevHref"
                    type="text"
                    placeholder="Prev Project Slug"
                    className={
                      "form-control" +
                      (errors.prevHref && touched.prevHref ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="prevHref"
                    component="div"
                    className="invalid-feedback"
                  />
                  <Field
                    name="nextHref"
                    type="text"
                    placeholder="Next Project Slug"
                    className={
                      "form-control" +
                      (errors.nextHref && touched.nextHref ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="nextHref"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              {/*
       <div className="form-row">
              <div className="form-group col">
                <div className="editorContainer">
                  <label>Descriere</label>
                  <Field name="description" value={value} type="hidden" className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')} />
                  <ReactQuill
                    className="editor"
                    theme="snow"
                    value={value}
                    onChange={setValue}
                  />
                  <ErrorMessage name="description" component="div" className="invalid-feedback" />
                </div>
              </div>
            </div>
 */}

              <div className="form-row">
                <div className="form-group col">
                  <div className="editorContainer">
                    <RichTextEditor
                      content={value}
                      placeholder="Descrierea Informatii complete"
                      handleContentChange={setValue}
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-8">
                  <label></label>
                  <Field
                    name="image"
                    type="hidden"
                    value={pimg}
                    className={
                      "form-control" +
                      (errors.img && touched.img ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="image"
                    component="div"
                    className="invalid-feedback"
                  />

                  <label className="file btn btn-sm btn-info" htmlFor="file">
                    Incarca imaginea
                  </label>

                  <Field
                    innerRef={filesharhe_ref}
                    type="file"
                    id="file"
                    name="myfile"
                    onChange={handleClick}
                    accept=".jpg, .png, .gif, .svg, .webp"
                  />
                  <ErrorMessage name="myfile" />
                  {pimg && !file && (
                    <img
                      src={
                        process.env.REACT_APP_API_URL + "/test/files/" + pimg
                      }
                      alt={pimg}
                      height="30vh"
                      align="center"
                      className="img-fluid img-responsive"
                    />
                  )}

                  {imagePreviews && (
                    <>
                      {imagePreviews.map((img, i) => {
                        return (
                          <img
                            src={img}
                            alt={"image-" + i}
                            key={i}
                            height="80px"
                            align="center"
                            className="img-fluid img-responsive"
                          />
                        );
                      })}
                    </>
                  )}
                </div>
                <div className="form-group col-4">
                  <label>
                    Mai multe poze{" "}
                    <Button
                      variant="contained"
                      color="info"
                      endIcon={<CloudUploadIcon />}
                      onClick={openForm}
                    >
                      {" "}
                      Incarca
                    </Button>
                  </label>
                  {/*   <Field
                    name="moreImage"
                    type="text"
                    className={
                      "form-control" +
                      (errors.client && touched.moreImage ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="moreImages"
                    component="div"
                    className="invalid-feedback"
                  /> */}

                  {(gal===true) && (
                    <Dragimg defaultList={moreImages} onChange={handleChange} />
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-7">
                  <label>Seo Slug</label>

                  <Field
                    name="slug"
                    type="text"
                    value={slug}
                    readOnly
                    className={
                      "form-control" +
                      (errors.slug && touched.slug ? " is-invalid" : "")
                    }
                  />

                  <ErrorMessage
                    name="slug"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>

              <div className="form-group">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary"
                >
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  Salvează
                </button>
                <Link
                  to={isAddMode ? "/project" : "/project"}
                  className="btn btn-link"
                >
                  Abandon
                </Link>
              </div>
            </Form>
            <Dialog
              fullWidth
              open={open}
              onClose={handleClose}
              TransitionComponent={Transition}
            >
              <AppBar sx={{ position: "relative" }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography
                    sx={{ ml: 2, mx: 20, flex: 1 }}
                    variant="h6"
                    component="div"
                  >
                    Alege imagini din Blibolteca
                  </Typography>
                  <Button autoFocus color="inherit" onClick={handleClose}>
                    <CloseIcon />
                  </Button>
                </Toolbar>
              </AppBar>
              <BiblImg defaultList={moreImages} onChange={handleChange} />
            </Dialog>
            {modalOpen && (
              <Modal
                isOpen={true}
                onRequestClose={closeModal}
                ariaHideApp={false}
                style={customModalStyles}
              >
                <CloseButton onClick={closeModal}>X</CloseButton>
                {   

                            
                 (selectedModal==="Addimg" ?
                  <BiblImg
                    defaultList={moreImages.length ? moreImages : ""}
                    onChange={handleChange}
                  /> :
                  <WriteSeo currSeo={currentPage.Seo} Oninchid={closeModal} />
                  )
            }
                
              </Modal>
            )}
          </Box>
        );
      }}
    </Formik>
  );
};

export { WriteProject };
