import React, { useEffect, useState, useRef } from 'react';

import { Link, useParams } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from "axios";
import http from "../../http-common";
import UploadService from "../../_services/FileUploadService";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import { authActions } from '_store';
import { OrdersService, alertService } from '../../_services';
import { ordersActions } from '../../_store';

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./style.scss";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import slugify from 'react-slugify';
import { Box } from '@mui/material';
import Header from "../../components/Header";

const WriteOrders = () => {
  const state = useLocation().state;
  //console.log(state);
  const navigate = useNavigate();
  //  const { id } = state?.id; //match.params;
  const [id, setId] = useState(state?.id || 0);
  const [imagePreviews, setImagePreviews] = useState([]);
  //console.log(id);
 // const [value, setValue] = useState(state?.description || "");
  //console.log(state?.title);
  const [slug, setSlug] = useState(state?.slug || "");
  const [main, setMain] = useState(state?.main || false);
  const [title, setTitle] = useState(state?.title || "");
  const [file, setFile] = useState(null);
  const [pimg, setPimg] = useState(state?.image || "");
  const [slider, setSlider] = useState(state?.slider || false);
  const [mainId, setMainId] = useState(state?.MainId || false);
  const [allmain, setAllmain] = useState(null);
  const isAddMode = !id;
  const authUser = useSelector(x => x.auth.user);
  const initialValues = {
    title: title,
    slug: slug,
    image: pimg,
    main: main,
    slider:slider,
    MainId:mainId
  };
 const dispatch = useDispatch();

  useEffect(() => {

   OrdersService.getMain().then(x => setAllmain(x));

      //  dispatch(ordersActions.getMain());
//setAllmain(useSelector(x => x.orders));
	// const { categ } = dispatch(ordersActions.getMain());
  // //console.log(orders.data.data);
  }, []);
    //const { orders } = useSelector(x => x.orders);

   	//const { orders } = useSelector(x => x.orders);
	//if(orders) {
	//  setAllmain(orders);
	//}
//console.log(allmain);
if(allmain) {
//console.log(allmain.data);
};

//   //console.log(orders);
 //useSelector(x => x.orders);
////console.log(categ );
 const filesharhe_ref = useRef();
  const upload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await UploadService.upload(file);
      // setPimg(res.data.message]);
      //console.log(res.data.message);
      return res.data;
    } catch (err) {
      //console.log(err);
      toast.error(err, {
        position: toast.POSITION.TOP_LEFT
      });
    }
  };

  async function loadimg() {

    if (file) {
      const imgUrl = await upload();
    };

  };

  const handleClick = async (e) => {
    e.preventDefault();
    setFile(e.target.files[0]);
    setPimg(e.target.files[0].name);
    let images = [];

    for (let i = 0; i < e.target.files.length; i++) {
      images.push(URL.createObjectURL(e.target.files[i]));
    }

    //  setSelectedFiles(event.target.files);
    setImagePreviews(images);
   // //window.alert(imagePreviews);
  //  //window.alert(JSON.stringify({ imagePreviews }));
    /* if(file) {
     const imgUrl = await upload();
          setPimg(imgUrl.message);
     // //window.alert(pimg);
     // fields["img"]=pimg;
   };*/
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required('Nume este obligatoriu'),
 myfile: Yup.mixed().test('2097152', "Uploaded file is too big."
                ,(value) => {
                   return(
                    value && filesharhe_ref.current ?
                        (filesharhe_ref.current.files[0].size<=2097152? true: false)
                         : true)
                }),

  });

  function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    loadimg();

    //console.log(fields);
   // fields["description"] = value;
    fields["image"] = pimg;
    fields["main"] = main;
        fields["slider"] = slider;
	if(main) {
	fields["MainId"] = null;
	};

    fields["slug"] = slugify(fields["title"]);
   // //window.alert(JSON.stringify({ fields }));
    if (isAddMode) {
      createOrders(fields, setSubmitting);
    } else {
      updateOrders(id, fields, setSubmitting);
    }
  }

  function createOrders(fields, setSubmitting) {

    OrdersService.create(fields)
      .then(() => {
        toast.success("Comanda adăugată cu succes");
        alertService.success('Categoria a fost adaugata', { keepAfterRouteChange: true });

        navigate("/orders");
      })
      .catch(error => {
        setSubmitting(false);
	//console.log(error.response.data.message);
	toast.error(error.response.data.message);

        alertService.error(error);


      });
  }

  function updateOrders(id, fields, setSubmitting) {
//console.log("Update"+id);
    OrdersService.update(id, fields)
      .then(() => {
        alertService.success('Update successful', { keepAfterRouteChange: true });
        toast.success("Actualizare cu succes", {
          position: toast.POSITION.TOP_RIGHT
        });
        navigate("/orders");
      })
      .catch(error => {
        setSubmitting(false);
	//console.log(error.response.data.message);
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        alertService.error(error);
      });
  }



  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ errors, touched, isSubmitting, setFieldValue }) => {

        return (
	 <Box m="20px">
          <Form>
            <h1>{isAddMode ? 'Adaugă Comanda Produs' : 'Editează Comanda Produs'}</h1>
            <div className="form-row">
              <div className="form-group col-9">
                <label>Nume</label>
                <Field name="title" type="text"  className={'form-control' + (errors.title && touched.title ? ' is-invalid' : '')} />
                <ErrorMessage name="title" component="div" className="invalid-feedback" />
              </div>
	                    <div className="form-group col-3">
<small>Sunt acceptate poze doar cu extensia jpg, gif, png, webp sub 2Mb</small>
              </div>
	     </div>
	<div className="form-row">

	      <div className="form-group col">
		       <label>Principala {main ? (
                        <div className="badge badge-primary">Da</div>
                      ) : (
                        <div className="badge badge-danger">Nu</div>
                      )}

		        <Field
                          style={{ marginLeft: "1.5em", marginTop: "1.3em" }}
                          type="checkbox"
                          name="main"  onChange={(e) => setMain(!main)} className={'form-control d-none' +  (errors.main && touched.main ? ' is-invalid' : '')}
                        />
                                <ErrorMessage name="main" component="div" className="invalid-feedback" />

                         </label>
             </div>
	     	      <div className="form-group col">
		       <label>In Slider {slider ? (
                        <div className="badge badge-success">Da</div>
                      ) : (
                        <div className="badge badge-info">Nu</div>
                      )}

		        <Field
                          style={{ marginLeft: "1.5em", marginTop: "1.3em" }}
                          type="checkbox"
                          name="slider"  onChange={(e) => setSlider(!slider)} className={'form-control d-none' +  (errors.slider && touched.slider ? ' is-invalid' : '')}
                        />
                                <ErrorMessage name="slider" component="div" className="invalid-feedback" />

                         </label>
             </div>

            </div>

            <div className="form-row">
            <div className="form-group col-8">
                <label></label>
                <Field name="image" type="hidden"  value={pimg} className={'form-control' + (errors.img && touched.img ? ' is-invalid' : '')} />
                <ErrorMessage name="image" component="div" className="invalid-feedback" />


                <label className="file btn btn-sm btn-info" htmlFor="file">Incarca imaginea</label>

		    <Field innerRef={filesharhe_ref} type="file" id="file" name="myfile" onChange={handleClick}
                  accept=".jpg, .png, .gif, .svg, .webp"/>
                  <ErrorMessage name='myfile'  />
                {pimg && (!file) && (
                  <img src={process.env.REACT_APP_API_URL + "/test/files/" + pimg} alt={pimg} height="30vh" align="center" className='img-fluid img-responsive' />

                )}

                {imagePreviews && (
                  <>
                    {imagePreviews.map((img, i) => {
                      return (
                        <img src={img} alt={"image-" + i} key={i} height="80px" align="center" className='img-fluid img-responsive' />
                      );
                    })}
                  </>
                )}

              </div>
{!main ? (
              <div className="form-group col-4">
                <label>Comanda Superioara</label>
                <Field name="MainId" as="select" className={'form-control' + (errors.MainId && touched.MainId ? ' is-invalid' : '')}>
                  <option value=""></option>
{allmain && (
               <>
	      {allmain.data.map((princ, i) => {
                      return (
		      <option value={princ.id}>{princ.title}</option>
                        
                      );
                    })}
		    </>
 )}

                </Field>

                <ErrorMessage name="MainId" component="div" className="invalid-feedback" />
              </div>
	       ) : (
                        setAllmain(null)
                      )}
		      </div>
  <div className="form-row">
              <div className="form-group col-7">
                <label>Seo Slug</label>
                <Field name="uid" type="number" value={authUser?.id} className={'d-none'} />
                <Field name="slug" type="text" value={slug} readOnly className={'form-control' + (errors.slug && touched.slug ? ' is-invalid' : '')} />

                <ErrorMessage name="slug" component="div" className="invalid-feedback" />
              </div>
            </div>

            <div className="form-group">
              <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                Salvează
              </button>
              <Link to={isAddMode ? '/orders' : '/orders'} className="btn btn-link">Abandon</Link>
            </div>
          </Form>
	 </Box>
        );
      }}
    </Formik>
  );
}

export { WriteOrders };