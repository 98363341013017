import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { accountService, alertService } from "../_services";
import { history } from "../_helpers";
import { authActions } from "../_store";
import AuthLayout from "../hoc/authLayout";
import { toast } from "react-toastify";

export { Login };

function Login() {
  const dispatch = useDispatch();
  const authUser = useSelector((x) => x.auth.user);
  const authError = useSelector((x) => x.auth.error);
  const [data, setData] = useState("");
  const [linc, setLinc] = useState(0);
  if (authError) {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    ////console.log("Test");
    ////console.log(authError.message);
    ////console.log(JSON.stringify(authError));
    ////window.alert(authError);
  }
  //console.log(authError)
  useEffect(() => {
    // redirect to home if already logged in
    if (authUser) history.navigate("/");
    localStorage.removeItem("user");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("email is required"),
    password: Yup.string().required("Password is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  function onSubmit({ email, password }) {
    accountService
      .login(email, password)
      .then(() => {
        alertService.success(
          "Înregistrare reușită, vă rugăm să vă verificați e-mailul pentru instrucțiuni de verificare",
          { keepAfterRouteChange: true }
        );
        console.log("Autentificare reusita");
        toast.success("Autentificare reusita");
        return dispatch(authActions.login({ email, password }));
        history.navigate("/");
        // window.location.reload();
      })
      .catch((error) => {
        // //window.alert(error.response.data.message);
        ////console.log(JSON.stringify(error.response));
        //setSubmitting(false);
        setData(error);
        setLinc(linc + 1);
        console.log(linc);
        console.log(error);
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    // return dispatch(authActions.login({ email, password }));
  }

  return (
    <AuthLayout>
      <div className="container">
        <div className="row">
          {linc > 7 ? (
            <div className="col-sm-12 offset-sm-8 mt-8 container flex">
              <div className="alert alert-info text-danger">
                <h4>
                  Ai incercati de prea multe ori. Reveniti mai tarziu sau
                  contactati administratorul
                </h4>
              </div>
            </div>
          ) : (
            <div className="col-sm-4 offset-sm-4 mt-5">
              <div className="alert alert-info bg-secondary">
                <h4>CRM Caut Bona Potrivita</h4>
              </div>
              <div className="card">
                <h4 className="card-header">Autentificare</h4>
                <div className="card-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                      <label>email</label>
                      <input
                        name="email"
                        type="text"
                        {...register("email")}
                        className={`form-control ${
                          errors.email ? "is-invalid" : ""
                        }`}
                      />
                      <div className="invalid-feedback">
                        {errors.email?.message}
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Parola</label>
                      <input
                        name="password"
                        type="password"
                        {...register("password")}
                        className={`form-control ${
                          errors.password ? "is-invalid" : ""
                        }`}
                      />
                      <div className="invalid-feedback">
                        {errors.password?.message}
                      </div>
                    </div>
                    <button disabled={isSubmitting} className="btn btn-primary">
                      {isSubmitting && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      Autentificare
                    </button>
                    {authError && (
                      <div className="alert alert-danger mt-3 mb-0">{data}</div>
                    )}
                  </form>
                </div>
                <div className="form-row">
                  <div className="form-group col">
                    <Link to="/register" className="btn btn-link text-success">
                      Inregistreaza-te
                    </Link>
                  </div>
                  <div className="form-group col text-right">
                    <Link to="/forgot-password" className="btn btn-link pr-0">
                      Aţi uitat parola?
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </AuthLayout>
  );
}
