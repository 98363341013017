import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import RoomIcon from '@mui/icons-material/Room';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StarIcon from '@mui/icons-material/Star';
import { Box, Pagination, useTheme } from '@mui/material';
//import { useAppDispatch, useAppSelector } from 'app/hooks';
//import EmptyImg from 'assets/images/empty-shop.e78970f0.svg';
//import ToastBody from 'components/CustomToast/ToastBody';
//import { selectFoodFilter, shopFoodAction } from 'features/ShopFood/shopFoodSlice';
//import { Food } from 'models';
import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
// lazy load img js
//import { LazyLoadImage } from 'react-lazy-load-image-component';
// lazy load img css
//import 'react-lazy-load-image-component/src/effects/blur.css';
import { toast } from 'react-toastify';
//import foodApi from 'api/foodApi';
import './ShopOrder.scss';
import DOMPurify from "dompurify";
import { tokens } from "../../theme";
import moment from 'moment';

//export interface ShopProductProps {
//	bestFood: Food[];
//	getFoodById: (id: string) => void;
//}

//function ShopProduct({ bestFood, getFoodById }: ShopProductProps) {
const ShopOrder = ({ bestFood }) => {
const state = useLocation().state;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const order= bestFood
  const client = JSON.parse(bestFood.address);
  console.log("Client :",client, client.email, client['email'])
  const anunt= bestFood.Details[0].Doctor;
  console.log("Anunt : ",anunt, anunt.id)
 console.log(state);
 console.log(state?.id);
 console.log(bestFood);
 console.log("Produse : " , bestFood, bestFood.Details[0].Doctor["firstName"]);
 console.log(JSON.parse(bestFood.address));
 //const [bestFood, setBestFood] = useState(state?.Products || false);
 //console.log("Produse"+bestFood);

	//const dispatch = useAppDispatch();

	// const loading = useAppSelector(selectFoodLoading);
	//const filter = useAppSelector(selectFoodFilter);

	/*const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		dispatch(
			shopFoodAction.setFilter({
				...filter,
				_page: value,
			})
		);
	};*/

	//React toastify
	/*const showToast = () => {
		return toast(
			<ToastBody title="Success !" desc="Produsul a fost adăugat în coș" icon={true} />,
			{
				position: 'top-left',
				className: 'background__toast-success',
				closeButton: (
					<div
						style={{
							position: 'absolute',
							top: 8,
							right: 8,
							color: '#fff',
						}}
					>
						<ExitToAppIcon sx={{ width: '2rem !important', height: '2rem !important' }} />
					</div>
				),
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			}
		);
	};*/

	/*const handleGetFoodById = async (id: string, idx: number) => {
		const listFoodById = await foodApi.getFoodById(id);

		dispatch(shopFoodAction.getFoodById(listFoodById));
		dispatch(shopFoodAction.getIdFood(idx));

		showToast();
	};*/

	return (
		<Box >
			{/* {(!bestFood) && (
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
					}}
				>
					<CircularProgress
						sx={{
							color: '#ff514e',
						}}
					/>
				</Box>
			)} */}
 <div className="order-display p-4 border rounded shadow">

<div className="flex justify-between mb-2">

  <div>

	<p className="text-lg font-bold">Numar: {order.id} din {moment(order.date).format('DD.MM.YYYY')}</p>


  </div>

  <div>

	<p className="text-lg font-bold">Pret: {order.price} Ron</p>

	<p className="text-gray-600">Status: {order.status}</p>

  </div>

</div>

<div className="flex justify-between mb-2">



  <div className="w-1/2">

	<h4 className="text-lg font-bold">Pentru Anuntul</h4>

	<div className="flex justify-between mb-2">

	  <div>

		<p className="text-gray-600">Numar: {anunt.id}</p>

		<p className="text-gray-600">Nume: {anunt.firstName}</p>

		<p className="text-gray-600">Prenume: {anunt.lastName}</p>

	  </div>

	  <div>

		<img src={process.env.REACT_APP_API_URL + "/users/avatar/" +anunt.img} alt="Image" className="img-fluid rounded" width="60px" />

	  </div>

	</div>

  </div>

</div>

<div className="flex justify-between mb-2">

  <div className="w-1/2">

	<h4 className="text-lg font-bold">Date Client</h4>

	<div className="flex justify-between mb-2">

	  <div>

		<p className="text-gray-600">Nume: {client.name}</p>

		<p className="text-gray-600">Email: {client.email}</p>

	  </div>

	  <div>
	  <p className="text-gray-600">Companie: {client.company}</p>
	  <p className="text-gray-600">cui/cnp: {client.cif}</p>
	  <p className="text-gray-600">reg.com: {client.reg_com}</p>
	  <p className="text-gray-600">Client Nr.: {client.cod_client}</p>
	  <p className="text-gray-600">reg.com: {client.reg_com}</p>
		<p className="text-gray-600">Adresa: {client.address}</p>
		<p className="text-gray-600">Localitate: {client.city}</p>
		<p className="text-gray-600">Judet: {client.state}</p>
		<p className="text-gray-600">Tara: {client.country}</p>
		<p className="text-gray-600">Persoana de contact: {client.persoana_contact}</p>
		<p className="text-gray-600">Telefon: {client.phone}</p>
		<p className="text-gray-600">Banca: {client.bank}</p>
		<p className="text-gray-600">IBAN: {client.iban}</p>
		<p><strong>Platitor de TVA:</strong> {client.platitor_tva ? 'Da' : 'Nu'}</p>
	  </div>

	</div>

  </div>

</div>

</div>
			{/* 
			{bestFood.length > 0 ? (
				<div>
					<div className="shop-product">
						{bestFood.Products.map((food, id) => (
							<div key={id} className="shop-product_box">
								<div className="shop-product_box-main" >

									<div className="shop-product__content">
										<div className="shop-product__name">{food.title}</div>

								
										
										<div className="shop-product__row">
										<Link to={`/admin/products/write`} state={food} >
              <button className="btn btn-sm btn-primary mr-1"><i className="fas fa-edit"></i></button>
            </Link>
											<div className="shop-product__location">
												<RoomIcon />
												
											</div>
											<div className="shop-product__price">{`${food.price}Ron`}</div>
										</div>
									</div>
								</div>


							</div>
						))}
					</div>

				</div>
			) : (
				<div className="shop-product__empty">

					<div>Nu există niciun produs</div>
				</div>
			)}
			*/}
		</Box>
	);
}

export default ShopOrder;
