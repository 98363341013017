import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import RoomIcon from '@mui/icons-material/Room';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StarIcon from '@mui/icons-material/Star';
import { Box, Pagination, useTheme } from '@mui/material';
//import { useAppDispatch, useAppSelector } from 'app/hooks';
//import EmptyImg from 'assets/images/empty-shop.e78970f0.svg';
//import ToastBody from 'components/CustomToast/ToastBody';
//import { selectFoodFilter, shopFoodAction } from 'features/ShopFood/shopFoodSlice';
//import { Food } from 'models';
import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
// lazy load img js
//import { LazyLoadImage } from 'react-lazy-load-image-component';
// lazy load img css
//import 'react-lazy-load-image-component/src/effects/blur.css';
import { toast } from 'react-toastify';
//import foodApi from 'api/foodApi';
import './ShopProduct.scss';
import DOMPurify from "dompurify";
import { tokens } from "../../theme";

//export interface ShopProductProps {
//	bestFood: Food[];
//	getFoodById: (id: string) => void;
//}

//function ShopProduct({ bestFood, getFoodById }: ShopProductProps) {
const ShopProduct = ({ bestFood }) => {
const state = useLocation().state;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
 console.log(state);
 console.log(state?.id);
 console.log(bestFood);
 console.log("Produse" + bestFood.Products);
 //const [bestFood, setBestFood] = useState(state?.Products || false);
 //console.log("Produse"+bestFood);

	//const dispatch = useAppDispatch();

	// const loading = useAppSelector(selectFoodLoading);
	//const filter = useAppSelector(selectFoodFilter);

	/*const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		dispatch(
			shopFoodAction.setFilter({
				...filter,
				_page: value,
			})
		);
	};*/

	//React toastify
	/*const showToast = () => {
		return toast(
			<ToastBody title="Success !" desc="Produsul a fost adăugat în coș" icon={true} />,
			{
				position: 'top-left',
				className: 'background__toast-success',
				closeButton: (
					<div
						style={{
							position: 'absolute',
							top: 8,
							right: 8,
							color: '#fff',
						}}
					>
						<ExitToAppIcon sx={{ width: '2rem !important', height: '2rem !important' }} />
					</div>
				),
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			}
		);
	};*/

	/*const handleGetFoodById = async (id: string, idx: number) => {
		const listFoodById = await foodApi.getFoodById(id);

		dispatch(shopFoodAction.getFoodById(listFoodById));
		dispatch(shopFoodAction.getIdFood(idx));

		showToast();
	};*/

	return (
		<Box >
			{/* {(!bestFood) && (
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
					}}
				>
					<CircularProgress
						sx={{
							color: '#ff514e',
						}}
					/>
				</Box>
			)} */}
			{bestFood.Products.length > 0 ? (
				<div>
					<div className="shop-product">
						{bestFood.Products.map((food, id) => (
							<div key={id} className="shop-product_box">
								<div className="shop-product_box-main" >
									{/* <div className="shop-product__img-wrapper">
										<LazyLoadImage
											effect="blur"
											src={process.env.REACT_APP_API_URL + "/products/image/" +food.image}
											className="shop-product__img"
											alt=""
											width="80%"
											height="80%"
										></LazyLoadImage>

									</div>*/}

									<div className="shop-product__content">
										<div className="shop-product__name">{food.title}</div>

								
										
										<div className="shop-product__row">
										<Link to={`/admin/products/write`} state={food} >
              <button className="btn btn-sm btn-primary mr-1"><i className="fas fa-edit"></i></button>
            </Link>
											<div className="shop-product__location">
												<RoomIcon />
												
											</div>
											<div className="shop-product__price">{`${food.price}Ron`}</div>
										</div>
									</div>
								</div>


							</div>
						))}
					</div>

				</div>
			) : (
				<div className="shop-product__empty">

					<div>Nu există niciun produs</div>
				</div>
			)}
		</Box>
	);
}

export default ShopProduct;
