import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useConfirm } from "material-ui-confirm";
import { DoctorService } from '../../_services';
import { doctorActions } from '../../_store';
import Header from "../../components/Header";
import { Box, Typography, useTheme } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  roRO,
} from '@mui/x-data-grid';
import { tokens } from "../../theme";
import { DeleteOutline } from '@mui/icons-material';
import { toast } from "react-toastify";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function DoctorList({ match }) {
  const confirm = useConfirm();
  const theme = useTheme();
  const dispatch = useDispatch();
  const colors = tokens(theme.palette.mode);
  const { path } = useLocation();// match;

  const [doctor, setDoctor] = useState(null);

  useEffect(() => {

  DoctorService.getAll().then(x => setDoctor(x.data));
  //      dispatch(doctorActions.getAll());
//setDoctor(useSelector(x => x.doctor));
//setProduct(doctor);
  }, []);

// const { doctor } = useSelector(x => x.doctor);
////console.log({doctor});

const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) => doctor.find((row) => row.id === id));
    //console.log(selectedRowsData);
  };


 const deleteDoctor = (id) => {
     confirm({title:'Esti Sigur', description: `Se va șterge definitiv Anuntul ${id}.`,confirmationText:'da',cancellationText:'renunta' })
       .then(() => {
          setDoctor(doctor.map(x => {
            if (x.id === id) { x.isDeleting = true; }
            return x;
        }));
        DoctorService.remove(id).then(() => {
            setDoctor(doctor => doctor.filter(x => x.id !== id));
            toast.success("Anuntul a fost sters cu succes");
            toast.error("Ștergerea a fost anulată");
        });
      })
      .catch(() => {
       //console.log("Deletion cancelled.")
      });
  };


      const columns = [
    { field: "id", headerName: "Id", hide: true  },
    {
      field: "firstName",
      headerName: "Nume",
      width: 100,
      cellClassName: "name-column--cell",
    },
        {
      field: "lastName",
      headerName: "Prenume",
      width: 100,
      cellClassName: "name-column--cell",
    },
{
    field: 'img',
    headerName: 'Poza',
    width: 90,
      renderCell: (params: any) => {
        return (
          <>
{params.row.img  ? (
                         <img src={process.env.REACT_APP_API_URL + "/users/avatar/" + params.row.img} className="img-fluid rounded" width="60px" />
                      ) : (
                         <img src="assets/logo.png" className="img-fluid" width="60px" />
                      )}
          </>
        )
      }
  },

 {
    field: 'active',
    headerName: 'Status',
    width: 90,
      renderCell: (params: any) => {
        return (
          <>
{params.row.active  ? (
                        <div className="badge badge-primary">Activ</div>
                      ) : (
                        <div className="badge badge-danger">Inactiv</div>
                      )}
          </>
        )
      }
  },
  {
    field: "hits",
    headerName: "Hits",
    width: 100,
    cellClassName: "name-column--cell",
  },

{
      field: 'action',
      headerName: '',
      width: 100,
      renderCell: (params: any) => {
        return (
          <>
            <Link to={`/team/write`} state={params.row} >
              <button className="btn btn-sm btn-primary mr-1"><i className="fas fa-edit"></i></button>
            </Link>
	    <DeleteOutline className='btn btn-xs btn-sm btn-danger' style={{ width: '30px', height : '30px' }} onClick={() => deleteDoctor(params.row.id)}/>

          </>
        )
      }
    }
  ];

function getStatus(params) {
//console.log(params);
 return `${!params.row.active || 'Activ'} ${params.row.active || 'Inactiv'}`

};

    return (
        <Box m="20px">
        <div>
            <h1>Workers Anunturi</h1>

            <Link to={`/team/write`} className="btn btn-sm btn-success mb-2">Adăugați un Anunt</Link>
	            
	     {doctor && <Box
        m="8px 0 0 0"
        height="80vh"
	width= "90%"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiChackbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >

	  <DataGrid
               rows={doctor}
               columns={columns}
	       pageSize={5}
	       localeText={roRO.components.MuiDataGrid.defaultProps.localeText} 
               components={{ Toolbar: CustomToolbar }}
	       onSelectionModelChange={(ids) => {
          //console.log(ids);
	  //console.log(ids[0]);
onRowsSelectionHandler(ids);
	 // //console.log(params);
        }}
        />
      </Box> }
 
        </div>
        </Box>
    );
}

export { DoctorList };