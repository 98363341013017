import React, { useEffect, useState, useRef } from "react";

import { Link, useParams } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
//import axios from "axios";
//import http from "../../http-common";
import UploadService from "../../_services/FileUploadService";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { authActions } from "_store";
import { DoctorService, alertService, accountService } from "../../_services";
import { doctorActions } from "../../_store";
import AutoSelect from "../../_helpers/autoselect";

import Select, { OptionsType, ValueType } from "react-select";
import { Options, OnChangeValue } from "react-select";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./style.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import slugify from "react-slugify";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import Program from "./Program";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { CloseButton } from "../../components/styles/CalendarStyles";
import Modal from "react-modal";
import { useConfirm } from "material-ui-confirm";
import { judete } from "../../data/judete";
import moment from 'moment';

const Arrays = (data, fieldName, fieldValue) => {
  let arrayItem = [];
  if (data && Array.isArray(data)) {
    data.map((item, key) => {
      arrayItem.push({ label: item[fieldName], value: item[fieldValue] });
      return null;
    });
  }
  return arrayItem;
};

const WriteDoctor = () => {
  const state = useLocation().state;
  const confirm = useConfirm();
  const navigate = useNavigate();
  const authUser = useSelector((x) => x.auth.user);
  const [id, setId] = useState(state?.id || 0);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [value, setValue] = useState(state?.description || "");
  const [description, setDescription] = useState(state?.description || "");
  const [firstName, setFirstName] = useState(state?.firstName || "");
  const [active, setActive] = useState(state?.active || false);
  const [isGeneralist, setIsGeneralist] = useState(
    state?.isGeneralist || false
  );
  const [isPaid, setIsPaid] = useState(state?.isPaid || false);
  const [lastName, setLastName] = useState(state?.lastName || "");
  const [file, setFile] = useState(null);
  const [pimg, setPimg] = useState(state?.img || "");
  const [judet, setJudet] = useState(state?.judet || "");
  const [localitate, setLocalitate] = useState(state?.localitate || "");
  // const [price, setPrice] = useState(state?.price || 0);
  // const [positionId, setPositionId] = useState(Arrays(state?.Position , "title", "id")|| []);
  const [positionId, setPositionId] = useState(state?.PositionId || "");
  const [userId, setUserId] = useState(state?.UserId || "");
  const [email, setEmail] = useState(state?.email || authUser.email);
  const [phone, setPhone] = useState(state?.phone || authUser.phone);
  const [startdate, setStartdate] = useState(state?.startdate || null);
  const [days, setDays] = useState(state?.days || 0);
  const [hits, setHits] = useState(state?.hits || 0);
  const [shortDescription, setShortDescription] = useState(
    state?.shortDescription || ""
  );
  //const [userId, setUserId] = useState(Arrays(state?.User , "username", "id")|| []);
  const [allmain, setAllmain] = useState(null);
  const [allcateg, setAllcateg] = useState(null);
  const [alldoctor, setAlldoctor] = useState(null);
  const [users, setUsers] = useState(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedModal, setSelectedModal] = useState("");
  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    overlay: { zIndex: 1000 },
  };
  const openForm = () => {
    setSelectedModal("Program");
    openModal();
  };

  // Open Modal Function
  const openModal = () => {
    setModalOpen(true);
  };

  // Close Modal Function
  const closeModal = () => {
    setModalOpen(false);
  };

  const isAddMode = !id;
  //const authUser = useSelector((x) => x.auth.user);
console.log("startdate", startdate, moment(startdate).format("DD.MM.YYYY"));
  const initialValues = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    phone: phone,
    shortDescription: shortDescription,
    description: value,
    img: pimg,
    judet: judet,
    localitate: localitate,
    isGeneralist: isGeneralist,
    isPaid: isPaid,
    active: active,
    PositionId: positionId,
    UserId: userId,
  };

  const dispatch = useDispatch();

  useEffect(() => {
    DoctorService.getAllposition().then((x) => setAllmain(x.data));
    accountService.getAll().then((x) => setUsers(x));
  }, []);
  //const { categories } = useSelector(x => x.categories);

  //const { categories } = useSelector(x => x.categories);
  //if(categories) {
  //  setAllmain(categories);
  //}
  //console.log(allmain);
  if (allmain && !allcateg) {
    console.log("Functii:", allmain);
    setAllcateg(Arrays(allmain, "title", "id"));
    console.log("pos:", allcateg);
  }
  if (users && !alldoctor) {
    //console.log(allmain.data);
    console.log(users);
    setAlldoctor(Arrays(users, "username", "id"));
    console.log(alldoctor);
    //console.log(allcateg);
  }
  //   //console.log(categories);
  //useSelector(x => x.categories);
  ////console.log(categ );

  const filesharhe_ref = useRef();
  const upload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await UploadService.uploadavatar(file);
      console.log("res :", res.data);
      setPimg(res.data);
      console.log("res :", res.data, pimg);
      return res.data;
    } catch (err) {
      toast.error(err, {
        position: toast.POSITION.TOP_LEFT,
      });
      return false;
    }
  };

  async function loadimg() {
    try {
      if (file) {
        const imgUrl = await upload();
        //  fields["avatar"]=imgUrl;
        //  setPimg(imgUrl);

        console.log("imgUrl : ", imgUrl, pimg);
        // setPimg(imgUrl);
        // return true
      }
    } catch (err) {
      toast.error(err, {
        position: toast.POSITION.TOP_LEFT,
      });
      // return false;
    }
  }

  const handleClick = async (e) => {
    e.preventDefault();
    if (e.target.files[0].size > 2097152) {
      toast.error("Dimensiunea fișierului nu trebuie să depășească 2 MB", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setFile(e.target.files[0]);
      console.log(
        "fis : ",
        e.target.files[0].size,
        e.target.files[0],
        file,
        filesharhe_ref.current
      );
      //setFieldValue("myfile", e.currentTarget.files[0]);
      //fields["myfile"] = e.target.files[0];
      //setField("myfile", e.target.files[0]);
      setPimg(e.target.files[0].name);
      let images = [];

      for (let i = 0; i < e.target.files.length; i++) {
        images.push(URL.createObjectURL(e.target.files[i]));
      }

      setImagePreviews(images);
    }
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Nume este obligatoriu"),
    email: Yup.string()
      .email("Email is invalid")
      .required("Email este obligatoriu"),
    phone: Yup.string().required("Telefonul este obligatoriu"),
    judet: Yup.string().required("Judetul este obligatoriu"),
    localitate: Yup.string().required("Localitate este obligatoriu"),
    PositionId: Yup.number().required("Functie este obligatorie"),
    UserId: Yup.number().required("Utilizator asociat este obligatorie"),
    myfile: Yup.mixed().test(
      "2097152",
      "Fișierul încărcat este prea mare.",
      (value) => {
        return value && filesharhe_ref.current
          ? filesharhe_ref.current.files[0].size <= 2097152
            ? true
            : false
          : true;
      }
    ),
  });

  async function onSubmit(fields, { setStatus, setSubmitting }) {
    try {
      //
      //const ok = await loadimg();
      // fields["active"] = status;
      if (file) {
        const imgUrl = await upload();
        //  fields["avatar"]=imgUrl;
        //  setPimg(imgUrl);

        if (imgUrl) {
          fields["img"] = imgUrl;
          setPimg(imgUrl);
          console.log("imgUrl : ", imgUrl, fields["avatar"]);
        }
        // setPimg(imgUrl);
        // return true
      }

      setStatus();
      //fields["avatar"] = pimg;
      if (pimg === "") {
        fields["img"] = pimg;
      }
      console.log("myfile : ", fields["avatar"], pimg);
      // setStatus();
      //loadimg();

      console.log(fields);
      fields["shortDescription"] = shortDescription;
      fields["description"] = value;
      //fields["img"] = pimg;
      fields["active"] = active;
      fields["isGeneralist"] = isGeneralist;
      fields["isPaid"] = isPaid;
      // window.alert(JSON.stringify({ fields}));
      // console.log(Object.values(PositionId)[1]);
      // console.log(Object.values(UserId)[1]);
      //const Pid = PositionId.map((e) => e.value);
      //console.log(Pid );

      // fields["slug"] = slugify(fields["title"]);
      //window.alert(JSON.stringify({ fields }));
      if (isAddMode) {
        createDoctor(fields, setSubmitting);
      } else {
        updateDoctor(id, fields, setSubmitting);
      }
    } catch (err) {
      toast.error(err, {
        position: toast.POSITION.TOP_LEFT,
      });
      // return false;
    }
  }

  function createDoctor(fields, setSubmitting) {
    DoctorService.create(fields)
      .then(() => {
        toast.success("Doctor adăugat cu succes");
        alertService.success("Produs adăugat cu succes", {
          keepAfterRouteChange: true,
        });

        navigate("/team");
      })
      .catch((error) => {
        setSubmitting(false);
        //console.log(error);
        toast.error(error.response.data.message);

        alertService.error(error);
      });
  }

  function updateDoctor(id, fields, setSubmitting) {
    //console.log("Update"+id);
    DoctorService.update(id, fields)
      .then(() => {
        alertService.success("Update successful", {
          keepAfterRouteChange: true,
        });
        toast.success("Actualizare cu succes", {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate("/team");
      })
      .catch((error) => {
        setSubmitting(false);
        //console.log(error.response.data.message);
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        alertService.error(error);
      });
  }
  async function deleteAvatar(e, name) {
    e.preventDefault();
    //if (window.confirm("Stergi imaginea? "+name))
    //{
    console.log("sterge:", name, name.split("_")[0]);
    const poza = name.split("_")[0];
    ////window.alert(name);
    //console.log("sterge "+name);
    // history("/");
    //let _progressInfos = [...progressInfosRef.current.val];
    confirm({
      title: "Esti Sigur",
      description: `Se va șterge definitiv imaginea ${poza}.`,
      confirmationText: "da",
      cancellationText: "renunta",
    })
      .then(() => {
        UploadService.deleteimgav(name, (event) => {})
          .then(() => {
            toast.success(
              "Imaginea a fost stearsa cu succes: " + name.split("_")[0]
            );
            setPimg("");
            //            fields["avatar"] = "";

            // return;
          })
          .catch(() => {
            //  _progressInfos[10].percentage = 0;
            //  setProgressInfos({ val: _progressInfos });
            //toast.error("Nu s-a putut sterge imaginea: " + name);
          });
        //}
      })
      .catch(() => {
        console.log("Deletion cancelled.");
      });
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, isSubmitting, setFieldValue }) => {
        return (
          <Box m="20px">
            <Form>
              <h1>
                {isAddMode ? "Adaugă Membru Echipa" : "Editează  Membru Echipa"}
              </h1>
              {isAddMode ? (
                ""
              ) : (
                <Button
                  color="secondary"
                  startIcon={<AddIcon />}
                  onClick={openForm}
                >
                  Program de lucru
                </Button>
              )}
              <div className="form-row">
                <div className="form-group col-6">
                  <label>Nume</label>
                  <Field
                    name="firstName"
                    type="text"
                    className={
                      "form-control" +
                      (errors.firstName && touched.firstName
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group col-6">
                  <label>Prenume</label>
                  <Field
                    name="lastName"
                    type="text"
                    className={
                      "form-control" +
                      (errors.lastName && touched.lastName ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-6">
                  <label>Email</label>
                  <Field
                    name="email"
                    type="text"
                    className={
                      "form-control" +
                      (errors.email && touched.email ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group col-6">
                  <label>Telefon</label>
                  <Field
                    name="phone"
                    type="text"
                    className={
                      "form-control" +
                      (errors.phone && touched.phone ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-6">
                  <label>Localitate</label>
                  <Field
                    name="localitate"
                    type="text"
                    className={
                      "form-control" +
                      (errors.localitate && touched.localitate
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="localitate"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group col-6">
                  <label>Judet</label>
                  <Field
                    name="judet"
                    as="select"
                    className={
                      "form-control" +
                      (errors.judet && touched.judet ? " is-invalid" : "")
                    }
                  >
                    <option value=""></option>
                    {judete && (
                      <>
                        {judete.map((pos) => {
                          return (
                            <option value={pos.nume} key={pos.abr}>
                              {pos.nume}
                            </option>
                          );
                        })}
                      </>
                    )}
                  </Field>

                  <ErrorMessage
                    name="judet"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-4">
                  <label>
                    Status{" "}
                    {active ? (
                      <div className="badge badge-primary">Activ</div>
                    ) : (
                      <div className="badge badge-danger">Inactiv</div>
                    )}
                    <Field
                      style={{ marginLeft: "1.5em", marginTop: "1.3em" }}
                      type="checkbox"
                      name="active"
                      onChange={(e) => setActive(!active)}
                      className={
                        "form-control d-none" +
                        (errors.active && touched.active ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="active"
                      component="div"
                      className="invalid-feedback"
                    />
                  </label>
                </div>
                <div className="form-group col-4">
                  <label>
                    Sposorizat{" "}
                    {isGeneralist ? (
                      <div className="badge badge-primary">Da</div>
                    ) : (
                      <div className="badge badge-danger">Nu</div>
                    )}
                    <Field
                      style={{ marginLeft: "1.5em", marginTop: "1.3em" }}
                      type="checkbox"
                      name="isGeneralist"
                      onChange={(e) => setIsGeneralist(!isGeneralist)}
                      className={
                        "form-control d-none" +
                        (errors.isGeneralist && touched.isGeneralist
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="isGeneralist"
                      component="div"
                      className="invalid-feedback"
                    />
                  </label>
                </div>
                <div className="form-group col-4">
                  <label>
                    Platit{" "}
                    {isPaid ? (
                      <div className="badge badge-primary">Da</div>
                    ) : (
                      <div className="badge badge-danger">Nu</div>
                    )}
                    <Field
                      style={{ marginLeft: "1.5em", marginTop: "1.3em" }}
                      type="checkbox"
                      name="isPaid"
                      onChange={(e) => setIsPaid(!isPaid)}
                      className={
                        "form-control d-none" +
                        (errors.isGeneralist && touched.isGeneralist
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="isGeneralist"
                      component="div"
                      className="invalid-feedback"
                    />
                  </label>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-8">
                  <label>Rezumat</label>
                  {/* <Field name="shortDescription" type="text"  className={'form-control' + (errors.shortDescription && touched.shortDescription ? ' is-invalid' : '')} /> */}
                  <ReactQuill
                    className="editor"
                    theme="snow"
                    value={shortDescription}
                    onChange={setShortDescription}
                  />
                  <ErrorMessage
                    name="shortDescription"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group col-4">
                  <small>
                    Sunt acceptate poze doar cu extensia jpg, gif, png, webp sub
                    2Mb. Rezolutie recomandata 1024x1024
                  </small>
                  {startdate && <p>date de inceput : {moment(startdate).format("DD.MM.YYYY")}, Zile : {days} </p>}
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col">
                  <div className="editorContainer">
                    <label>Descriere</label>
                    <Field
                      name="description"
                      value={value}
                      type="hidden"
                      className={
                        "form-control" +
                        (errors.description && touched.description
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ReactQuill
                      className="editor"
                      theme="snow"
                      value={value}
                      onChange={setValue}
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-8">
                  {pimg && !file ? (
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={(e) => deleteAvatar(e, pimg)}
                    >
                      <span>
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </span>
                      Sterge Poza
                    </button>
                  ) : (
                    <>
                      <label
                        className="file btn btn-sm btn-info"
                        htmlFor="file"
                      >
                        Incarca Poza
                      </label>

                      <Field
                        innerRef={filesharhe_ref}
                        type="file"
                        name="myfile"
                        accept=".jpg, .png, .gif, .svg, .webp"
                        onChange={handleClick}
                      />
                      <ErrorMessage
                        name="myfile"
                        component="div"
                        className="invalid-feedback"
                      />
                    </>
                  )}
                  {pimg && !file && (
                    <img
                      src={
                        process.env.REACT_APP_API_URL + "/users/avatar/" + pimg
                      }
                      alt={pimg}
                      height="30vh"
                      align="center"
                      className="img-fluid img-responsive"
                    />
                  )}

                  {imagePreviews && (
                    <>
                      {imagePreviews.map((img, i) => {
                        return (
                          <img
                            src={img}
                            alt={"image-" + i}
                            key={i}
                            height="80px"
                            align="center"
                            className="img-fluid img-responsive"
                          />
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
              {/*<div className="form-row">
                <div className="form-group col-8">
                  <label></label>
                  <Field
                    name="image"
                    type="hidden"
                    value={pimg}
                    className={
                      "form-control" +
                      (errors.img && touched.img ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="image"
                    component="div"
                    className="invalid-feedback"
                  />

                  <label className="file btn btn-sm btn-info" htmlFor="file">
                    Incarca imaginea
                  </label>

                  <Field
                    innerRef={filesharhe_ref}
                    type="file"
                    id="file"
                    name="myfile"
                    onChange={handleClick}
                    accept=".jpg, .png, .gif, .svg, .webp"
                  />
                  <ErrorMessage name="myfile" />
                  {pimg && !file && (
                    <img
                      src={
                        process.env.REACT_APP_API_URL + "/test/files/" + pimg
                      }
                      alt={pimg}
                      height="30vh"
                      align="center"
                      className="img-fluid img-responsive"
                    />
                  )}

                  {imagePreviews && (
                    <>
                      {imagePreviews.map((img, i) => {
                        return (
                          <img
                            src={img}
                            alt={"image-" + i}
                            key={i}
                            height="80px"
                            align="center"
                            className="img-fluid img-responsive"
                          />
                        );
                      })}
                    </>
                  )}
                </div>
              </div> */}
              <div className="form-row">
                <div className="form-group col-6">
                  <label>Functie</label>
                  <Field
                    name="PositionId"
                    as="select"
                    className={
                      "form-control" +
                      (errors.PositionId && touched.PositionId
                        ? " is-invalid"
                        : "")
                    }
                  >
                    <option value=""></option>
                    {allcateg && (
                      <>
                        {allcateg.map((pos) => {
                          return (
                            <option value={pos.value} key={pos.value}>
                              {pos.label}
                            </option>
                          );
                        })}
                      </>
                    )}
                  </Field>

                  <ErrorMessage
                    name="PositionId"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group col-6">
                  <label>Utilizator asociat</label>
                  <Field
                    name="UserId"
                    as="select"
                    className={
                      "form-control" +
                      (errors.UserId && touched.UserId ? " is-invalid" : "")
                    }
                  >
                    <option value=""></option>
                    {alldoctor && (
                      <>
                        {alldoctor.map((pos) => {
                          return (
                            <option value={pos.value} key={pos.value}>
                              {pos.label}
                            </option>
                          );
                        })}
                      </>
                    )}
                  </Field>

                  <ErrorMessage
                    name="UserId"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>

              <div className="form-group">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary"
                >
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  Salvează
                </button>
                <Link
                  to={isAddMode ? "/team" : "/team"}
                  className="btn btn-link"
                >
                  Abandon
                </Link>
              </div>
            </Form>
            {modalOpen && (
              <Modal
                isOpen={true}
                onRequestClose={closeModal}
                ariaHideApp={false}
                style={customModalStyles}
              >
                <CloseButton onClick={closeModal}>X</CloseButton>
                {<Program doctorid={id} />}
              </Modal>
            )}
          </Box>
        );
      }}
    </Formik>
  );
};

export { WriteDoctor };
