import HomeIcon from "@mui/icons-material/Home";
import GroupsIcon from "@mui/icons-material/Groups";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import PersonIcon from "@mui/icons-material/Person";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import Shop2 from "@mui/icons-material/Shop2";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import SwitchRightOutlinedIcon from "@mui/icons-material/SwitchRightOutlined";
import SwitchLeftOutlinedIcon from "@mui/icons-material/SwitchLeftOutlined";
import HomeRepairServiceOutlinedIcon from "@mui/icons-material/HomeRepairServiceOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";

/* <Item
              title="Comenzi"
              to="/orders"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Clienti"
              to="/contacts"
              icon={<ContactsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Preturi"
              to="/products"
              icon={<MapOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Categorii preturi"
              to="/categories"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Workers"
              to="/team"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Pagini"
              to="/admin/pages"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Articole"
              to="/admin/posts"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Informatii"
              to="/project"
              icon={<AccountTreeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> 
                        <Item
              title="Servicii"
              to="/servicii"
              icon={<HomeRepairServiceOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />*/
const sidebarConfig = {
  admin: [
    {
      label: "Comenzi",
      link: "/orders",
      icon: <ReceiptOutlinedIcon />,
    },            
    {
      label: "Clienti",
      link: "/contacts",
      icon: <ContactsOutlinedIcon />,
    },
    {
      label: "Categorii preturi",
      link: "/categories",
      icon: <PieChartOutlineOutlinedIcon />,
    },
    {
      label: "Preturi",
      link: "/products",
      icon: <MapOutlinedIcon />,
    },
    {
      label: "Workers",
      link: "/team",
      icon: <BarChartOutlinedIcon />,
    },
    {
      label: "Pagini",
      link: "/admin/pages",
      icon: <PeopleOutlinedIcon />,
    },
    {
      label: "Articole",
      link: "/admin/posts",
      icon: <BarChartOutlinedIcon />,
    },
    {
      label: "Informatii",
      link: "/project",
      icon: <AccountTreeOutlinedIcon />,
    },
    {
      label: "Servicii",
      link: "/servicii",
      icon: <HomeRepairServiceOutlinedIcon />,
    },
  ],
  manager: [
    {
      label: "Pagini",
      link: "/admin/pages",
      icon: <PeopleOutlinedIcon />,
    },
    {
      label: "Articole",
      link: "/admin/posts",
      icon: <BarChartOutlinedIcon />,
    },
    {
      label: "Informatii",
      link: "/project",
      icon: <AccountTreeOutlinedIcon />,
    },
    {
      label: "Servicii",
      link: "/servicii",
      icon: <HomeRepairServiceOutlinedIcon />,
    },
  ],
  data_entry: [
    {
      label: "manageProducts",
      link: "/products",
      icon: <LocalMallIcon />,
    },
    {
      label: "manageOrders",
      link: "/orders",
      icon: <ShoppingCartIcon />,
    },
    {
      label: "personalArea",
      link: "/personal-area",
      icon: <PersonIcon />,
    },
  ],
  marketing: [
    {
      label: "manageCoupons",
      link: "/coupons",
      icon: <ConfirmationNumberIcon />,
    },
    {
      label: "personalArea",
      link: "/personal-area",
      icon: <PersonIcon />,
    },
  ],
};

export { sidebarConfig };
