import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useConfirm } from "material-ui-confirm";
import { OrdersService } from "../../_services";
import { ordersActions } from "../../_store";
import Header from "../../components/Header";
import { Box, Typography, useTheme } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  roRO,
} from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { DeleteOutline } from "@mui/icons-material";
import ShopOrder from "./ShopOrder";


function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function OrdersList({ match }) {
  const confirm = useConfirm();
  const theme = useTheme();
  const dispatch = useDispatch();
  const colors = tokens(theme.palette.mode);
  const { path } = useLocation(); // match;

  const [orders, setOrders] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  ///  const { orders } = useSelector(x => x.orders);
  useEffect(() => {
    const test1 = OrdersService.getAllwithproducts().then((x) => setOrders(x.data));
    //      dispatch(ordersActions.getAll());
    console.log("Comenzi : ",orders, test1);
  }, []);
console.log(orders);
  ////console.log(categorii);

   const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) =>
      orders.find((row) => row.id === id)
    );
    console.log(selectedRowsData[0].Details.length);
    console.log(JSON.stringify(selectedRowsData));
    setCurrentPage(selectedRowsData);
    // console.log(JSON.stringify(selectedRowsData));
    console.log(JSON.stringify(currentPage));
    //console.log(currentPage[0].slug);

    {
      currentPage
        ? console.log(
            "Produse : " +
              JSON.stringify(currentPage[0].slug + currentPage[0].Details)
          )
        : console.log("Nici o comanda");
    }
  };

  const deleteOrders = (id) => {
    confirm({
      title: "Esti Sigur",
      description: `Se va șterge definitiv Comanda ${id}.`,
      confirmationText: "da",
      cancellationText: "renunta",
    })
      .then(() => {
        setOrders(
          orders.map((x) => {
            if (x.id === id) {
              x.isDeleting = true;
            }
            return x;
          })
        );
        OrdersService.remove(id).then(() => {
          setOrders((orders) => orders.filter((x) => x.id !== id));
        });
      })
      .catch(() => {
        //console.log("Deletion cancelled.")
      });
  };

  const columns = [
    { field: "id", headerName: "Numar", hide: false },
    {
      field: "date",
      headerName: "Data",
      width: 100,
      cellClassName: "name-column--cell",
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      cellClassName: "name-column--cell",
    },
    {
      field: "price",
      headerName: "Pret",
      width: 100,
      cellClassName: "name-column--cell",
    },
    {
      field: "Details[0].Doctor['firstName']",
      headerName: "Nume",
      width: 200,
      renderCell: (params: any) => {
        return (
          <>
            {params.row.UserId ? (
              params.row.Details[0].Doctor['firstName']+" "+params.row.Details[0].Doctor['lastName']

            ) : (
                 ""
            )}
          </>
        );
      },
    },

    {
      field: "isPaid",
      headerName: "Platit",
      width: 90,
      renderCell: (params: any) => {
        return (
          <>
            {params.row.isPaid ? (
              <div className="badge badge-primary">Da</div>
            ) : (
              <div className="badge badge-danger">Nu</div>
            )}
          </>
        );
      },
    },
   
    {
      field: "action",
      headerName: "",
      width: 100,
      renderCell: (params: any) => {
        return (
          <> {/* 
            <Link to={`/admin/orders/write`} state={params.row}>
              <button className="btn btn-sm btn-primary mr-1">
                <i className="fas fa-edit"></i>
              </button>
            </Link> */}
            <DeleteOutline
              className="btn btn-xs btn-sm btn-danger"
              style={{ width: "30px", height: "30px" }}
              onClick={() => deleteOrders(params.row.id)}
            />
          </>
        );
      },
    },
  ];

  function getStatus(params) {
    //console.log(params);
    return `${!params.row.active || "Activ"} ${params.row.active || "Inactiv"}`;
  }

  return (
    <Box m="20px">
      <div>
        <h1>Comenzi</h1>
{/*
        <Link
          to={`/admin/orders/write`}
          className="btn btn-sm btn-success mb-2"
        >
          Adăugați o Comanda
        </Link>
 */}
        {orders && (
          <Box
            m="8px 0 0 0"
            height="50vh"
            width="90%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiChackbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            <DataGrid
              rows={orders}
              columns={columns}
              pageSize={5}
              localeText={roRO.components.MuiDataGrid.defaultProps.localeText}
              components={{ Toolbar: CustomToolbar }}
              onSelectionModelChange={(ids) => {
                //console.log(ids);
                //console.log(ids[0]);
                onRowsSelectionHandler(ids);
                // //console.log(params);
              }}
            />
          </Box>
        )}

        <div className="col-md-6">
          {currentPage ? (
            <div>
              <div>
                <label>
                  <strong>Informatii pentru  comanda:</strong>
                </label>{" "}
                {currentPage[0].title}
              </div>

              <div>
                <ShopOrder bestFood={currentPage[0]} />
              </div>
            </div>
          ) : (
            <div>
              <br />
              <p>Vă rugăm să faceți clic pe un numar...</p>
            </div>
          )}
        </div>
      </div>
    </Box>
  );
}

export { OrdersList };
